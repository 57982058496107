/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import sysuser from '@/components/common/SysUser';
import sysgroup from '@/components/common/SysGroup';
import rxboxs from '@/components/common/rx-boxs';
import RxAttachment from '@/components/common/form/RxAttachment';
import router from '@/router/index';
import { RxUtil } from "@/assets/util.js";
import { storage } from "../../assets/myjs/glovar";
import { convertBoJson } from '@/assets/app.js';
import { getNodeUserJson } from '@/assets/flow/bpmtask.js';
Vue.component(sysuser.name, sysuser);
Vue.component(sysgroup.name, sysgroup);
Vue.component(rxboxs.name, rxboxs);
Vue.component(RxAttachment.name, RxAttachment);
export default {
  name: "rx-approve",
  data() {
    return {
      bpmTypeName: '',
      bpmTask: {},
      destNodeUserList: [],
      isReachEndEvent: false,
      taskConfig: {},
      isMulti: false,
      showUserDialog: false,
      showGroupDialog: false,
      opinion: "同意",
      //目标节点
      destNodeId: "",
      //选中的的rx-boxs实例，用于传递数据。
      nodeUserVm: {},
      nodeGroupVm: {},
      files: "[]",
      jumpType: "AGREE",
      ccUserIds: "",
      canReject: false,
      errorModuleData: {},
      errorModuleData2: {}
    };
  },
  watch: {
    jumpType: function (val) {
      if (val == 'AGREE') {
        this.opinion = '同意';
      } else if (val == 'REFUSE') {
        this.opinion = '反对';
      } else if (val == 'ABSTAIN') {
        this.opinion = '弃权';
      }
    }
  },
  mounted() {
    this.bpmTypeName = this.$route.params.bpmTypeName;
    console.log(this.$route.params);
    this.errorModuleData = this.$store.state.bpmModule.errorModuleData;
    console.log("errorModuleData======", this.errorModuleData);
    this.errorModuleData2 = this.$store.state.bpmModule.errorModuleData2;
    console.log("errorModuleData======", this.errorModuleData2);
    var data = this.$store.state.bpmModule.taskData;
    console.info("this.files:" + this.files);
    this.bpmTask = data.bpmTask;
    console.log("data = ", data);
    this.approveUserList(data.formModels);
    this.taskConfig = data.taskConfig;
    this.canReject = data.canReject;
    this.isMulti = data.isMulti;
  },
  computed: {
    allowPathSelect: function () {
      var tmp = this.taskConfig.allowPathSelect;
      return tmp == "true" ? true : false;
    },
    allowNextExecutor: function () {
      var tmp = this.taskConfig.allowNextExecutor;
      return tmp == "true" ? true : false;
    },
    allowChangePath: function () {
      var tmp = this.taskConfig.allowChangePath;
      return tmp == "true" ? true : false;
    }
  },
  methods: {
    approveUserList(formModels) {
      var jsonData = convertBoJson(formModels);
      // var jsonData = [];
      // if(formModels[0].formKey != "ypcg"){
      // 	jsonData = convertBoJson(formModels);
      // }else{
      // 	var ypcgMessge = JSON.parse(storage.getItem("ypcgMessge"));
      // 	var wpLists = JSON.parse(storage.getItem("wpLists"));
      // 	var formModel = formModels[0];
      // 	var data = {};
      // 	data.boDefId = formModel.boDefId;
      // 	data.formKey = formModel.formKey;
      // 	data.data = formModel.jsonData;
      // 	data.data.CGRQ = ypcgMessge.cgrq;
      // 	data.data.CGSQR = ypcgMessge.cgsqr;
      // 	data.data.CGSY = ypcgMessge.cgsy;
      // 	data.data.SUB_datagrid1 = wpLists;
      // 	jsonData.push(data);
      // }
      var postData = {
        taskId: this.bpmTask.id,
        jsonData: jsonData
      };
      console.log("approve.vue-  postData =", postData);
      let jsonStr = this.replaceUnicode(postData);
      var approveCmd = {
        postData: jsonStr,
        taskId: this.bpmTask.id,
        jumpType: this.jumpType
      };
      var url = _baseUrl + "/bpm/core/bpmTask/approveUserList.do";
      // var url = _baseUrl + "";

      console.log("approve.vue-  approveCmd =", approveCmd);
      var params = RxUtil.serialJsonToForm(approveCmd);
      console.log("approve.vue-  params =", params);
      this.$ajax.post(url, params).then(response => {
        if (response.status == 200) {
          this.destNodeUserList = response.data.list;
          this.isReachEndEvent = response.data.isReachEndEvent;
        } else {
          this.errorToast("没有获取到节点信息");
        }
      }).catch(error => {});
    },
    changeOpinion(e) {
      var v = e.srcElement.value;
      this.opinion = v;
    },
    /**
     * 打开用户对话框
     */
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    /**
     * 打开用户组对话框
     */
    selectGroupDialog(vm) {
      this.showGroupDialog = true;
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    /**
     * 点击用户对话框选择按钮，处理用户对话框返回数据。
     */
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    //确定审批
    handApprove() {
      this.approve(this.jumpType);
    },
    /**
     * 构建cmd数据。
     */
    getProcessCmd(action) {
      var task = this.bpmTask;
      var approveCmd = {};
      approveCmd.taskId = task.id;
      approveCmd.jumpType = action;
      approveCmd.opinion = this.opinion;
      approveCmd.isValid = true;
      approveCmd.message = "success";
      approveCmd.ccUserIds = this.handleCcUserIds();
      approveCmd.destNodeId = this.destNodeId;
      var data = this.$store.state.bpmModule.taskData;
      console.log(data.formModels);
      var jsonData = convertBoJson(data.formModels); //在这里处理

      if (this.bpmTypeName == '服务与供用品采购') {
        console.log('此模块加入了 errorModuleData');
        // jsonData.bos[0].data = this.errorModuleData;
        // jsonData.bos[0].data.SUB_datagrid1 =this.errorModuleData2;
        jsonData = this.errorModuleData;
        jsonData.SUB_datagrid1 = this.errorModuleData2;
      } else if ('期间核查计划' == this.bpmTypeName) {
        jsonData = this.errorModuleData;
        jsonData.SUB_datagrid1 = this.errorModuleData2;
      }
      console.log(jsonData);
      let jsonStr = this.replaceUnicode(jsonData);
      console.log(jsonStr);
      approveCmd.jsonData = jsonStr;
      approveCmd.opFiles = this.files;
      if (this.$refs.destNodeId && !this.destNodeId) {
        approveCmd.isValid = false;
        approveCmd.message = "下一级节点没有选择审批人";
        return approveCmd;
      }
      //[{nodeId:"",userIds:""},{nodeId:"",userIds:""}]
      var destNodeUsers = getNodeUserJson(this.destNodeUserList);
      approveCmd.destNodeUsers = encodeURIComponent(destNodeUsers);
      return approveCmd;
    },
    handleCcUserIds() {
      if (this.ccUserIds && !(this.ccUserIds instanceof Array)) {
        var ccUserIdsArray = JSON.parse(this.ccUserIds);
        var strs = [];
        for (let i = 0, j = ccUserIdsArray.length; i < j; i++) {
          let obj = ccUserIdsArray[i];
          if (obj && obj.id) {
            strs.push(obj.id);
          }
        }
        return encodeURIComponent(strs.toString());
      }
      return "";
    },
    cancel() {
      router.go(-1);
    },
    approve(action) {
      var approveCmd = this.getProcessCmd(action);
      //获取数据失败。
      if (!approveCmd.isValid) {
        this.errorToast(approveCmd.message);
        return;
      }
      delete approveCmd.isValid;
      delete approveCmd.message;
      var url = _baseUrl + "/bpm/core/bpmTask/doNext.do";
      console.log('审批参数如下=》approveCmd', approveCmd);
      var params = RxUtil.serialJsonToForm(approveCmd);

      //console.log('审批参数如下=》params',params);
      //return;
      this.$dialog.loading.open("正在审批...");
      this.$ajax.post(url, params).then(response => {
        this.$dialog.loading.close();
        var data = response.data;
        if (data.success) {
          this.successToast("审批成功！", 1500, 'index', {
            from: 'approve'
          });
        } else {
          this.errorToast(data.message, "1500");
        }
      }).catch(error => {
        this.$dialog.loading.close();
        console.log(error);
      });
    }
  }
};